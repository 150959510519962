import { useI18nStore } from '@/stores/i18nStore'
import { useDynamicContentStore } from '@/stores/dynamicContent'

export function useDynamicContent() {
  const dynamicContentStore = useDynamicContentStore()
  const i18nStore = useI18nStore()

  async function getDynamicContent({ contents = [], getAll = false }) {
    const locale = i18nStore.langCode(true)
    console.log('getDynamicContent', locale)
    let toUpdateContents = null

    if (contents?.length) {
      toUpdateContents = dynamicContentStore.contentNeedsUpdate({
        contents,
        locale,
      })
    }

    const fullContentStored = dynamicContentStore.getFullContentStored

    if ((getAll && !fullContentStored) || toUpdateContents?.length) {
      await dynamicContentStore.fetchDynamicContent({
        content: getAll ? contents : toUpdateContents,
        locale,
        getAll,
      })
    }
  }

  return {
    getDynamicContent,
  }
}
